<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>分销商信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            label="手机:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.mobile }}
                        </el-form-item>
                        <el-form-item
                            label="分销商姓名:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.distributorName }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>提现信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            label="提现类型:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.withdrawTypeName }}
                        </el-form-item>
                        <el-form-item
                            label="提现来源:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.withdrawSourceName }}
                        </el-form-item>
                        <el-form-item
                            label="提现金额:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.withdrawAmount }}
                        </el-form-item>
                        <el-form-item
                            label="提现手续费:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.withdrawPoundage }}
                        </el-form-item>
                        <el-form-item
                            label="提现金额(扣除手续费):"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.withdrawAmount - distributorWithdraw.withdrawPoundage }}
                        </el-form-item>
                        <el-form-item
                            label="账户姓名:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.accountName }}
                        </el-form-item>
                        <el-form-item
                            label="账户账号:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.accountNum }}
                        </el-form-item>
                        <el-form-item
                            label="提现时间:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.withdrawTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                        <el-form-item
                            label="提现状态:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.withdrawStatusName }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="distributorWithdraw.withdrawStatus !== 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>处理信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            label="处理操作人:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.operatorName }}
                        </el-form-item>
                        <el-form-item
                            label="处理时间:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.paymentTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                        <el-form-item
                            label="实际付款金额:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.paymentAmount }}
                        </el-form-item>
                        <el-form-item
                            label="付款凭证:"
                            label-width="8em"
                        >
                            <ImageList
                                :data="distributorWithdraw.paymentVoucherList"
                            />
                        </el-form-item>
                        <el-form-item
                            label="备注:"
                            label-width="8em"
                        >
                            {{ distributorWithdraw.remarks }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="distributorWithdraw.withdrawStatus === 1"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>处理信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            prop="paymentAmount"
                            label="实际付款金额"
                            label-width="8em"
                        >
                            <el-input-number
                                controls-position="right"
                                :min="0"
                                :precision="2"
                                v-model.number="formModel.paymentAmount"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="paymentVoucher"
                            label="审核凭证"
                            label-width="8em"
                        >
                            <upload
                                action="/rs/attachment/uploadShopWithdrawVoucherImage"
                                :limit="5"
                                v-model="formModel.paymentVoucher"
                            />
                        </el-form-item>
                        <el-form-item
                            prop="remarks"
                            label="备注"
                            label-width="8em"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.remarks"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            label-width="8em"
                        >
                            <el-button
                                type="primary"
                                size="small"
                                @click="onConfirmPass()"
                            >
                                付款
                            </el-button>
                            <el-button
                                type="primary"
                                size="small"
                                @click="onConfirmErr()"
                            >
                                取消提现
                            </el-button>
                        </el-form-item>
                    </div>
                </el-card>
                <el-col :span="12">
                    <el-form-item
                        label-width="8em"
                        class="ma-t"
                    >
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'DistributorWithdrawDetail',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                paymentAmount: '',
                paymentVoucher: [],
                remarks: '',
            },
            // 表单校验规则
            formRules: {
                paymentAmount: {
                    required: true,
                    message: '请输入实际付款金额',
                    trigger: 'blur',
                },
                remarks: {
                    required: true,
                    message: '请输入备注',
                    trigger: 'blur',
                },
                paymentVoucher: {
                    type: 'array',
                    required: true,
                    message: '付款凭证不能为空',
                },
            },
            distributorWithdraw: {
                paymentVoucherList: [],
            },
        };
    },
    methods: {
        init() {
            this.$api.Mb.DistributorWithdraw.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.distributorWithdraw = res.distributorWithdraw || {};
            });
        },
        onConfirmPass() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm('是否确定付款！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.$api.Mb.DistributorWithdraw.payDistributorWithdraw({
                        ...this.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.init();
                    });
                });
            });
        },
        onConfirmErr() {
            this.$confirm('是否确定取消该提现申请！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Mb.DistributorWithdraw.cancelDistributorWithdraw({
                    ...this.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                });
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
